import React from "react";

export default class AafConfirmations extends React.Component {
    render() {
        return (
            <div>
                <div style={{ width: '100vw', height: '100vh', overflow: 'hidden', margin: 0, padding: 0 }}>
                    <iframe
                        src="https://mjvk9559u5.execute-api.ap-south-1.amazonaws.com/aaf"
                        title="Full Page Iframe"
                        style={{ width: '100%', height: '100%', border: 'none' }}
                    />
                </div>
            </div>
        );
    }
}