import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons"
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className="footer">
            <footer className="py-5 bg-grey center">
                <Container>
                    <Row>
                        <Col md={4} className="light-bg">
                            <div className="footer-header">CONTACT INFORMATION</div>
                            <p>
                                {/*<a href="mailto:info@tangentindia.org">info@tangentindia.org</a>*/}
                                {/*<br/>*/}
                                <a href="mailto:mail.tangentindia@gmail.com">mail.tangentindia@gmail.com</a>
                            </p>

                            <div className="social-icons">
                                <a className="social-icon"
                                   href="https://www.facebook.com/Tangent-India-111094508006197/"
                                   rel="noopener noreferrer"
                                   target="_blank"><FontAwesomeIcon icon={faFacebookF}/></a>
                                <a className="social-icon"
                                   href="https://www.facebook.com/Tangent-India-1308296459240216/"
                                   rel="noopener noreferrer"
                                   target="_blank"><FontAwesomeIcon icon={faFacebookF}/></a>
                                <a className="social-icon" href="https://www.instagram.com/tangent_india/"
                                   rel="noopener noreferrer"
                                   target="_blank"><FontAwesomeIcon icon={faInstagram}/></a>
                                <a className="social-icon"
                                   href="https://www.youtube.com/channel/UCMp5WrTSmnyGK2OAAvMmEXA/"
                                   rel="noopener noreferrer"
                                   target="_blank"><FontAwesomeIcon icon={faYoutube}/></a>
                            </div>
                        </Col>
                        <Col md={4} className='text-center'>
                            <h2>Links</h2>
                            <p>
                                <a href='https://directory.tangentindia.org/'>Tangent Directory</a><br />
                                <a href='https://payments.tangentindia.org/'>Payment Gateway</a><br />
                                <Link to={'/administrivia'}>Downloads & Administrivia</Link><br/>
                                <Link to={'/new-membership'}>New Member Form</Link><br/>
                                <Link to={'/privacy'}>Privacy Policy</Link><br/>
                                <Link to={'/terms-conditions'}>Terms and Conditions</Link><br/>
                                <Link to={'/pricing-refunds-cancellations'}>Pricing</Link><br/>
                                <Link to={'/pricing-refunds-cancellations'}>Refunds & Cancellations</Link>
                            </p>
                        </Col>
                        <Col md={4}>
                            <img src='https://assets.tangentindia.org/assets/national-logo.jpg' width={'100%'} className="footer-logo" alt="Tangent India Logo"/>
                            <p>
                                Make yours an active life.<br/>
                                Discover new friends everyday.<br/>
                                Get a life without limits.<br/>
                                Get Active. Live Life.
                            </p>
                        </Col>
                        {/*<Col md className="light-bg">*/}
                        {/*    <div className="footer-header">TANGENT INDIA</div>*/}
                        {/*    <iframe*/}
                        {/*        title="Location of Tangent India on Google Maps"*/}
                        {/*        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.558350697474!2d80.24163391482301!3d13.063760290795484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526668ae9e5851%3A0xdec25bea4cce7b64!2sRound+Table+House%2C+69%2C+Nungambakkam+High+Rd%2C+Subba+Road+Avenue%2C+Nungambakkam%2C+Chennai%2C+Tamil+Nadu+600034!5e0!3m2!1sen!2sin!4v1549973656092"*/}
                        {/*        width="600"*/}
                        {/*        height="450"*/}
                        {/*        frameBorder="0"*/}
                        {/*        className="iframe"*/}
                        {/*        allowFullScreen=""/>*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </footer>
        </div>
    );
}

export default Footer;
