import React from "react";
import Col from 'react-bootstrap/Col';

export default class OfficeBearerInfo extends React.Component {
    render() {
        return (
            <Col className="office-bearer center" sm={4}>
                <strong>Tangent Club No. {this.props.number} – {this.props.city}</strong>
                <p>
                    Chairperson - {this.props.chairperson}<br/>
                    Secretary - {this.props.secretary}
                </p>
            </Col>
        );
    }
}