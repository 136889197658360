import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Seo from "../../Seo";

export default function PricingRefundsCancellations() {
    const sectionStyle = {
        backgroundImage: "url('https://assets.tangentindia.org/assets/newsletter-banner.jpeg')"
    };
    return (
        <div id="newsletter">
            <Seo title='Pricing, Refunds and Cancellations | Tangent India'
                 description="Pricing, Refunds and Cancellations Policy of Tangent India"
            />
            <div id="newsletter-header-background" style={sectionStyle}>
                <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'
                     className="hidden header-foreground" alt=""/>
            </div>
            <div className="center title">
                <h1>Pricing, Refunds and Cancellations</h1>
            </div>
            <div className="section">
                <Container>
                    <h2>
                        Pricing
                    </h2>
                    <p>
                        Name bars - ₹150<br/>
                        Tangent Pin - ₹125<br/>
                        Past Chairperson Pin - ₹250<br/>
                        Club Secretary Pin - ₹300<br/>
                        New Member Fee - ₹3000<br/>
                        AAF - ₹1000/member<br/>
                        Charter Fees - ₹1000
                    </p>
                    <h2>
                        Refunds and Cancellations
                    </h2>
                    <p>
                        Tangent India is unable to process any refunds or cancellations. Once an order has been placed and payment made, no cancellations or refunds may be provided of any sort.
                    </p>
                </Container>
            </div>
        </div>
    );
}