import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Seo from "../../Seo";

function Publications() {
    const sectionStyle = {
        backgroundImage: "url('https://assets.tangentindia.org/assets/newsletter-banner.jpeg')"
    };
    return (
        <div id="newsletter">
            <Seo title='Publications | Tangent India'
                 description="Tangent India Publications. Happenings is Tangent India's regular publication with the happenings of the club. Tangent Times is Tangent India's regular newsletter with content from Tangent India's clubs and members."
            />
            <div id="newsletter-header-background" style={sectionStyle}>
                <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'
                     className="hidden header-foreground" alt=""/>
            </div>
            <div className="center title">
                <h1>Publications</h1>
            </div>
            <div className="section">
                <Container>
                    <Row>
                        <TangentTimes />
                    </Row>
                    <Row>
                        <Happenings />
                    </Row>
                </Container>
            </div>
        </div>
    );
}

function TangentTimes() {
    return (
        <Col>
            <Row>
                <div className="center title">
                    <h2>Tangent Times</h2>
                </div>
            </Row>
            <Row>
                <Col lg>
                    <h2>2024 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2024/aug.pdf'
                               target='_blank' rel='noreferrer'>August 2024</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2024/may.pdf'
                               target='_blank' rel='noreferrer'>May 2024</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2024/jan.pdf'
                               target='_blank' rel='noreferrer'>January 2024</a>
                        </li>
                    </ul>
                </Col>
                <Col lg>
                    <h2>2023 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2023/sept.pdf'
                               target='_blank' rel='noreferrer'>September 2023</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2023/jan.pdf'
                               target='_blank' rel='noreferrer'>January 2023</a>
                        </li>
                    </ul>
                </Col>
                <Col lg>
                    <h2>2022 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2022/7.pdf'
                               target='_blank' rel='noreferrer'>July 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2022/6.pdf'
                               target='_blank' rel='noreferrer'>June 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2022/3.pdf'
                               target='_blank' rel='noreferrer'>March 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2022/1.pdf'
                               target='_blank' rel='noreferrer'>January 2022</a>
                        </li>
                    </ul>
                </Col>
                <Col lg>
                    <h2>2021 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2021/11.pdf'
                               target='_blank' rel='noreferrer'>November 2021</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2021/10.pdf'
                               target='_blank' rel='noreferrer'>October 2021</a>
                        </li>
                    </ul>
                </Col>
                <Col lg>
                    <h2>2020 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2020/7.pdf'
                               target='_blank' rel='noreferrer'>July 2020</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2020/2.pdf'
                               target='_blank' rel='noreferrer'>February 2020</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2020/1.pdf'
                               target='_blank' rel='noreferrer'>January 2020</a>
                        </li>
                    </ul>
                </Col>
                <Col lg>
                    <h2>2019 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2019/11.pdf'
                               target='_blank' rel='noreferrer'>November 2019</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2019/10.pdf'
                               target='_blank' rel='noreferrer'>October 2019</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/2019/9.pdf'
                               target='_blank' rel='noreferrer'>September 2019</a>
                        </li>
                    </ul>
                </Col>
                <Col lg>
                    <h2>Special Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/old/AGM-2.pdf'
                               target='_blank' rel='noreferrer'>Jimiki Kamal… Wanna Kam
                                Salem</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/newsletter/old/AGM-1.pdf'
                               target='_blank' rel='noreferrer'>National AGM Chandigarh
                                Issue</a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Col>
    );
}

function Happenings() {
    return (
        <Col>
            <Row>
                <div className="center title">
                    <h2>Happenings</h2>
                </div>
            </Row>
            <Row>
                <Col md>
                    <h2>2024 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2024/july.pdf'
                               target='_blank' rel='noreferrer'>July 2024</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2024/june.pdf'
                               target='_blank' rel='noreferrer'>June 2024</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2024/may.pdf'
                               target='_blank' rel='noreferrer'>May 2024</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2024/april.pdf'
                               target='_blank' rel='noreferrer'>April 2024</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2024/march.pdf'
                               target='_blank' rel='noreferrer'>March 2024</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2024/feb.pdf'
                               target='_blank' rel='noreferrer'>February 2024</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2024/jan.pdf'
                               target='_blank' rel='noreferrer'>January 2024</a>
                        </li>
                    </ul>
                </Col>
                <Col md>
                    <h2>2023 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2023/dec.pdf'
                               target='_blank' rel='noreferrer'>December 2023</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2023/nov.pdf'
                               target='_blank' rel='noreferrer'>November 2023</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2023/oct.pdf'
                               target='_blank' rel='noreferrer'>October 2023</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2023/sept.pdf'
                               target='_blank' rel='noreferrer'>September 2023</a>
                        </li>
                        <li>
                            <a href='https://online.fliphtml5.com/xuexd/osum/'
                               target='_blank' rel='noreferrer'>July 2023</a>
                        </li>
                        <li>
                            <a href='https://online.fliphtml5.com/xuexd/njks/'
                               target='_blank' rel='noreferrer'>June 2023</a>
                        </li>
                        <li>
                            <a href='https://online.fliphtml5.com/xuexd/vgdx/'
                               target='_blank' rel='noreferrer'>May 2023</a>
                        </li>
                        <li>
                            <a href='https://online.fliphtml5.com/xuexd/dloi/'
                               target='_blank' rel='noreferrer'>April 2023</a>
                        </li>
                        <li>
                            <a href='https://online.fliphtml5.com/xuexd/cjsv/'
                               target='_blank' rel='noreferrer'>February 2023</a>
                        </li>
                        <li>
                            <a href='https://online.fliphtml5.com/xuexd/hwht/'
                               target='_blank' rel='noreferrer'>January 2023</a>
                        </li>
                    </ul>
                </Col>
                <Col md>
                    <h2>2022 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://online.fliphtml5.com/xuexd/dnby/'
                               target='_blank' rel='noreferrer'>November 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/october.pdf'
                               target='_blank' rel='noreferrer'>October 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/aug.pdf'
                               target='_blank' rel='noreferrer'>August 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/july.pdf'
                               target='_blank' rel='noreferrer'>July 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/june.pdf'
                               target='_blank' rel='noreferrer'>June 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/may.pdf'
                               target='_blank' rel='noreferrer'>May 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/april.pdf'
                               target='_blank' rel='noreferrer'>April 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/march.pdf'
                               target='_blank' rel='noreferrer'>March 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/feb.pdf'
                               target='_blank' rel='noreferrer'>February 2022</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2022/jan.pdf'
                               target='_blank' rel='noreferrer'>January 2022</a>
                        </li>
                    </ul>
                </Col>
                <Col md>
                    <h2>2021 Issues</h2>
                    <ul>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2021/nov_dec.pdf'
                               target='_blank' rel='noreferrer'>November-December 2021</a>
                        </li>
                        <li>
                            <a href='https://assets.tangentindia.org/happenings/2021/oct.pdf'
                               target='_blank' rel='noreferrer'>October 2021</a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Col>
    );
}

export default Publications;