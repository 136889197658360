import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {getUser} from "./utils";
import Seo from "../../Seo";
import ClubScreen from "./ClubScreen";
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import {AiOutlineDelete, AiOutlineEdit, AiOutlineSearch} from "react-icons/ai";
import {Link} from "react-router-dom";

export default class AutoUpdatedDirectory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            amigos: [],
            searchVal: '',
            updated: false,
            directory_edit_permissions: false
        }
        this.changeSearchVal = this.changeSearchVal.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
        this.downloadPdf = this.downloadPdf.bind(this);
    }

    changeSearchVal(e) {
        this.setState({
            searchVal: e.target.value,
        })
    }

    downloadExcel() {
        var list = [];

        this.state.members.forEach(club => {
            const clubInfo = club['clubNum']['S'] + ' - ' + club['city']['S'];
            list.push({
                club: clubInfo,
                position: 'Chairperson',
                name: club['chairpersonName']['S'],
                address: club['chairpersonAddress']['S'],
                email: club['chairpersonEmail']['S'],
                phone: club['chairpersonPhone']['S'],
                dob: convertDob(club['chairpersonDob']['S']),
            })
            list.push({
                club: clubInfo,
                position: 'Secretary',
                name: club['secretaryName']['S'],
                address: club['secretaryAddress']['S'],
                email: club['secretaryEmail']['S'],
                phone: club['secretaryPhone']['S'],
                dob: convertDob(club['secretaryDob']['S']),
            })
            JSON.parse(club['members']['S']).forEach(member => {
                list.push({
                    ...member,
                    club: clubInfo,
                    position: 'Member',
                    dob: convertDob(member['dob']),
                })
            })
        })

        this.state.amigos.forEach(amigo => {
            list.push({
                club: '',
                position: 'Amigo',
                name: amigo['name']['S'],
                address: amigo['address']['S'],
                email: amigo['email']['S'],
                phone: amigo['phone']['S'],
                dob: convertDob(amigo['dob']['S']),
            })
        })

        let csvContent = "data:text/csv;charset=utf-8,"
                + 'Club,Position,Name,Email,Phone,Date of Birth,Address\n'
                + list.map(element => [element['club'], element['position'], element['name'], element['email'], element['phone'], element['dob'], '"' + element['address'].replaceAll('#', 'No.') + '"'].join(',')).join('\n')

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "tangent_directory_" + new Date().toLocaleString().replaceAll(':', '_').replaceAll('/', '_').replaceAll(' ', '_').replaceAll(',', '') + ".csv");
        document.body.appendChild(link);

        link.click();
    }

    downloadPdf() {
        window.open('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/pdf-members?userId=' + getUser()['sub'], '_blank')
    }

    componentDidMount() {
        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/members?userId=' + getUser()['sub'])
            .then(response => response.json())
            .then(response => [response[0]['Items'], response[1]['Items']])
            .then(items => {
                items[0].sort(GetSortOrder("clubNum")); //Pass the attribute to be sorted on
                return items;
            })
            .then(response => this.setState({
                members: response[0],
                amigos: response[1],
                updated: true
            }))

        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/admin?userId=' + getUser()['sub'])
            .then(response => response.json())
            .then(response => {
                this.setState({
                    ...this.state,
                    directory_edit_permissions: response['form_permission'],
                })
            })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
        };
        return (
            <div>
                <Seo title='Member Directory | Tangent India'
                     description='Directory of Tangent India Members.'
                     noindex={true}
                />
                <div id="home-header-background" style={sectionStyle}>
                    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'
                         className="hidden header-foreground" alt=""/>
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin">Admin</Breadcrumb.Item>
                    <Breadcrumb.Item active>Member Directory</Breadcrumb.Item>
                </Breadcrumb>
                <ClubScreen/>
                <div className='center title'>
                    <h1>Member Directory</h1>
                </div>
                <div className='section'>
                    <Container>
                            {this.state.members.length !== 0 && this.state.updated &&
                                <>
                                    <Row className='center'>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text><AiOutlineSearch /></InputGroup.Text>
                                            <FormControl id="inlineFormInputGroup" placeholder="Search"
                                                         value={this.state.searchVal}
                                                         onChange={this.changeSearchVal}
                                            />
                                        </InputGroup>
                                    </Row>
                                    <Row className='center small-padding'>
                                        <Col>
                                            <Button variant="outline-secondary" className='center' onClick={this.downloadExcel}>Download to Excel</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="outline-secondary" className='center' onClick={this.downloadPdf}>Download to PDF</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="outline-secondary" className='center' onClick={() => window.print()}>Print</Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                        <Row>
                            {this.state.members.length === 0 && this.state.updated ?
                                <h3 className='center'>You do not have permission to view this page</h3> :
                                this.state.members.map((club, idx) => {
                                    const showChairperson = includes(club['chairpersonName']['S'], this.state.searchVal);
                                    const showSecretary = includes(club['secretaryName']['S'], this.state.searchVal);
                                    const membersToShow = JSON.parse(club['members']['S']).filter(member => {
                                        return includes(member.name, this.state.searchVal)
                                    });
                                    if (membersToShow.length === 0 && !showChairperson && !showSecretary) {
                                        return (
                                            <></>
                                        )
                                    }
                                    return (
                                        <Col sm key={idx}>
                                            <ClubDirectory club={club} directory_edit_permissions={this.state.directory_edit_permissions} showChairperson={showChairperson} showSecretary={showSecretary} membersToShow={membersToShow}/>
                                        </Col>
                                    );
                                })}
                            {this.state.amigos.length > 0 && <AmigoDirectory directory_edit_permissions={this.state.directory_edit_permissions} amigosToShow={this.state.amigos.filter(amigo => {
                                return includes(amigo['name']['S'], this.state.searchVal)
                            })}/>}
                        </Row>
                    </Container>
                </div>
            </div>

        );
    }
}

//Comparer Function
function GetSortOrder(prop) {
    return function (a, b) {
        if (parseInt(a[prop]['S']) > parseInt(b[prop]['S'])) {
            return 1;
        } else if (parseInt(a[prop]['S']) < parseInt(b[prop]['S'])) {
            return -1;
        }
        return 0;
    }
}

class ClubDirectory extends React.Component {
    render() {
        return (
            <div className='center'>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th colSpan={5}>Club No. {this.props.club['clubNum']['S']} - {this.props.club['city']['S']}</th>
                        <td>
                            {this.props.directory_edit_permissions ?
                                <Button className='small-padding' variant="outline-secondary" as={Link}
                                        to={'/admin/annual-administrative-fee-form/edit/club/' + this.props.club['clubNum']['S']}><AiOutlineEdit/></Button> :
                                <Button className='small-padding' variant="outline-secondary" disabled><AiOutlineEdit/></Button>
                            }
                            {/*{this.props.directory_edit_permissions ?*/}
                            {/*    <Button className='small-padding' variant="outline-secondary"*/}
                            {/*            onClick={() => alert('delete')}><AiOutlineDelete/></Button> :*/}
                            {/*    <Button className='small-padding' variant="outline-secondary" disabled><AiOutlineDelete/></Button>*/}
                            {/*}*/}
                        </td>
                    </tr>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Date of Birth</th>
                        <th>Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.showChairperson &&
                        <tr>
                            <td>Chairperson</td>
                            <td>{this.props.club['chairpersonName']['S']}</td>
                            <td>{this.props.club['chairpersonEmail']['S']}</td>
                            <td>{this.props.club['chairpersonPhone']['S']}</td>
                            <td>{convertDob(this.props.club['chairpersonDob']['S'])}</td>
                            <td>{this.props.club['chairpersonAddress'] ? this.props.club['chairpersonAddress']['S'] : ''}</td>
                        </tr>
                    }
                    {this.props.showSecretary &&
                        <tr>
                            <td>Secretary</td>
                            <td>{this.props.club['secretaryName']['S']}</td>
                            <td>{this.props.club['secretaryEmail']['S']}</td>
                            <td>{this.props.club['secretaryPhone']['S']}</td>
                            <td>{convertDob(this.props.club['secretaryDob']['S'])}</td>
                            <td>{this.props.club['secretaryAddress'] ? this.props.club['secretaryAddress']['S'] : ''}</td>
                        </tr>
                    }
                    {this.props.membersToShow.map((memberInfo, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{memberInfo['name']}</td>
                                <td>{memberInfo['email']}</td>
                                <td>{memberInfo['phone']}</td>
                                <td>{convertDob(memberInfo['dob'])}</td>
                                <td>{memberInfo['address'] ? memberInfo['address'] : ''}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </div>
        );
    }
}

class AmigoDirectory extends React.Component {
    constructor(props) {
        super(props);
        this.deleteAmigo = this.deleteAmigo.bind(this);
    }

    deleteAmigo(amigoName, amigoPhone) {
        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/admin/amigo?userId=' + getUser()?.sub, {
            method: 'DELETE',
            body: JSON.stringify({
                name: amigoName,
                phone: amigoPhone
            })
        })
            .then(response => response.json())
            .then(response => alert(response['message']))
    }

    render() {
        if (this.props.amigosToShow.length === 0) {
            return (
                <></>
            )
        }
        return (
            <div className='center'>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th colSpan={6}>Amigos</th>
                    </tr>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Date of Birth</th>
                        <th>Address</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.amigosToShow.map((amigo, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{amigo['name']['S']}</td>
                                <td>{amigo['email']['S']}</td>
                                <td>{amigo['phone']['S']}</td>
                                <td>{convertDob(amigo['dob']['S'])}</td>
                                <td>{amigo['address']['S']}</td>
                                <td>
                                    {this.props.directory_edit_permissions ?
                                        <Button className='small-padding' variant="outline-secondary" as={Link}
                                                to={'/admin/annual-administrative-fee-form/edit/amigo/' + encodeURIComponent(amigo['name']['S'])}><AiOutlineEdit/></Button> :
                                        <Button className='small-padding' variant="outline-secondary" disabled><AiOutlineEdit/></Button>
                                    }
                                </td>
                                <td>
                                    {this.props.directory_edit_permissions ?
                                        <Button className='small-padding' variant="outline-secondary"
                                                onClick={() => this.deleteAmigo(amigo['name']['S'], amigo['phone']['S'])}><AiOutlineDelete/></Button> :
                                        <Button className='small-padding' variant="outline-secondary" disabled><AiOutlineDelete/></Button>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </div>
        );
    }
}

const MONTHS = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
}

function convertDob(badFormatString) {
    const month = MONTHS[parseInt(badFormatString.split('-')[1])]
    const day = parseInt(badFormatString.split('-')[2])
    return day + ' ' + month;
}

function includes(str1, str2) {
    return str1.toLowerCase().includes(str2.toLowerCase());
}