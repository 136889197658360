import React from "react";
import Seo from "../../Seo";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import ClubScreen from "./ClubScreen";
import Alert from "react-bootstrap/Alert";
import {Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {getUser} from "./utils";

export default class EventCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUser()
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();

        this.setState({
            validated: true
        })

        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/create-event', {
            method: 'POST',
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    ...this.state,
                    validated: true,
                    alert: true,
                    alertMessage: response['message'] ? response['message'] : 'Event added',
                    alertType: response['type'] ? response['type'] : 'success',
                    // name: '',
                    // email: '',
                    // message: ''
                });
            })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
        };
        return (
            <div id="home">
                <Seo title='Tangent India - Create Event' noindex={true}/>
                <div id="home-header-background" style={sectionStyle}>
                    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'
                         className="hidden header-foreground" alt=""/>
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href='/admin'>Admin</Breadcrumb.Item>
                    <Breadcrumb.Item active>Create Event</Breadcrumb.Item>
                </Breadcrumb>
                <ClubScreen/>
                <div className='title center'>
                    <h1>Create Event</h1>
                </div>
                <Container>
                    <Container className='section'>
                        {this.state.alertType ?
                            <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="eventName">
                                        <Form.Label>Event Name</Form.Label>
                                        <Form.Control required type="text" placeholder="Enter event name"
                                                      value={this.state.eventName}
                                                      name='eventName'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row><Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="eventDescription">
                                    <Form.Label>Event Description</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter event description"
                                                  value={this.state.eventDescription}
                                                  name='eventDescription'
                                                  onChange={this.changeState}/>
                                </Form.Group>
                            </Col>
                        </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="startDate">
                                        <Form.Label>Event Start Date</Form.Label>
                                        <Form.Control required type="date"
                                                      value={this.state.startDate}
                                                      name='startDate'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="startTime">
                                        <Form.Label>Event Start Time</Form.Label>
                                        <Form.Control required type="time"
                                                      value={this.state.startTime}
                                                      name='startTime'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="endDate">
                                        <Form.Label>Event End Date</Form.Label>
                                        <Form.Control required type="date"
                                                      value={this.state.endDate}
                                                      name='endDate'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="endTime">
                                        <Form.Label>Event End Time</Form.Label>
                                        <Form.Control required type="time"
                                                      value={this.state.endTime}
                                                      name='endTime'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="url">
                                        <Form.Label>Event URL</Form.Label>
                                        <Form.Control required type="input" placehold="Enter url"
                                                      value={this.state.url}
                                                      name='url'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="center">
                                    This form is being submitted
                                    by <strong>{this.state.user?.name} ({this.state.user?.email})</strong>.
                                </Col>
                            </Row>
                            <Row>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Row>
                        </Form>
                    </Container>
                </Container>
            </div>
        );
    }
}