import React from "react";
import Col from 'react-bootstrap/Col';

export default class BoardMember extends React.Component {
    render() {
        return (
            <Col className="board-member center">
                <div>
                    <img src={this.props.image ? this.props.image : "http://placehold.it/200x200"}
                         alt={this.props.name}
                         height='150'
                         width='150'
                    />
                </div>
                <div className="board-member-info">
                    <strong>{this.props.name}</strong>
                    <p>{this.props.city}</p>
                    <p>{this.props.position}</p>
                </div>
            </Col>
        );
    }
}