import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import BoardMember from "./BoardMember";
import RollOfHonor from "./RollOfHonor";
import OfficeBearerInfo from "./OfficeBearerInfo";
import Seo from "../../Seo";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

const board = [
    {
        "name": "Nalini Prasad",
        "city": "Vellore",
        "position": "President",
        "image": "https://assets.tangentindia.org/assets/team/2023/nalini-prasad.jpg"
    },
    {
        "name": "Smita Chopra",
        "city": "Bangalore",
        "position": "Vice President/National Extension Officer",
        "image": "https://assets.tangentindia.org/assets/team/smita-chopra.jpeg"
    },
    {
        "name": "Ranjana Singh",
        "city": "Bangalore",
        "position": "Imm Past President",
        "image": "https://assets.tangentindia.org/assets/team/2023/ranjana-singh.jpeg"
    },
    {
        "name": "Lakshmi Siddharthan",
        "city": "Salem",
        "position": "Secretary",
        "image": "https://assets.tangentindia.org/assets/team/lakshmi-siddharthan.jpeg"
    },
    {
        "name": "Archana Bhagat",
        "city": "Hyderabad",
        "position": "Treasurer",
        "image": "https://assets.tangentindia.org/assets/team/archana-bhagat.jpg"
    },
    {
        "name": "Sonal Desai",
        "city": "Kolkata",
        "position": "International Relations Officer",
        "image": "https://assets.tangentindia.org/assets/team/sonal-desai.jpeg"
    },
    {
        "name": "Hema Yadavalli",
        "city": "Vizag",
        "position": "Editor",
        "image": "https://assets.tangentindia.org/assets/team/2023/hema.png"
    },
    {
        "name": "PP Bijal Kalbag",
        "city": "Mumbai",
        "position": "Fellowship Convenor",
        "image": "https://assets.tangentindia.org/assets/team/2023/bijal.jpg"
    },
    {
        "name": "Shanthi U",
        "city": "Bangalore",
        "position": "Digital Convenor",
        "image": "https://assets.tangentindia.org/assets/team/2023/shanthi.jpg"
    },
    {
        "name": "Manjoo Shree",
        "city": "Salem",
        "position": "Mega Event",
        "image": "https://assets.tangentindia.org/assets/team/2023/manjoo-shree.png"
    },
    {
        "name": "Munira",
        "city": "Salem",
        "position": "Skill Sprint",
        "image": "https://assets.tangentindia.org/assets/team/2023/munira.jpg"
    },
    {
        "name": "Raji Asokan",
        "city": "Tirupur",
        "position": "Projects Convenor",
        "image": "https://assets.tangentindia.org/assets/team/2023/raji.jpg"
    },
    {
        "name": "Archana Dange",
        "city": "Tirupur",
        "position": "Projects Convenor",
        "image": "https://assets.tangentindia.org/assets/team/2023/archana-dange.jpg"
    },
    {
        "name": "Nanthini Manivannan",
        "city": "Vellore",
        "position": "Secretariat & Regalia Convenor",
        "image": "https://assets.tangentindia.org/assets/team/2023/nanthini.jpg"
    },
    {
        "name": "Rashmi Kale",
        "city": "Raipur",
        "position": "LC Contact Officer",
        "image": "https://assets.tangentindia.org/assets/team/rashmi-kale.jpeg"
    },
    {
        "name": "Yamini Deepak",
        "city": "Chennai",
        "position": "MTM Convenor",
        "image": "https://assets.tangentindia.org/assets/team/2023/yamini.png"
    },
    {
        "name": "Fareesa Khan",
        "city": "Hyderabad",
        "position": "AGM Convenor",
        "image": "https://assets.tangentindia.org/assets/team/2023/fareesa.png"
    },
    {
        "name": "Parul Dattani",
        "city": "Hyderabad",
        "position": "AGM Convenor",
        "image": "https://assets.tangentindia.org/assets/team/2023/parul-dattani.png"
    },
    {
        "name": "PP Renuka Sehgal",
        "city": "New Delhi",
        "position": "National Advisor",
        "image": "https://assets.tangentindia.org/assets/team/2023/renuka-sehgal.png"
    },
    {
        "name": "PP Jyoti Singh Deo",
        "city": "Bhubaneswar",
        "position": "National Advisor",
        "image": "https://assets.tangentindia.org/assets/team/2023/jyoti-singh-deo.jpg"
    },
    {
        "name": "PP Jayashree Choudhry",
        "city": "Mumbai",
        "position": "Board Mentor",
        "image": "https://assets.tangentindia.org/assets/team/2023/jayashree.jpg"
    }

    // {
    //     name: 'Ranjana Singh',
    //     city: 'Bangalore',
    //     position: 'President',
    //     image: 'https://assets.tangentindia.org/assets/team/ranjana-singh.jpeg'
    // },
    // {
    //     name: 'Nalini Prasad',
    //     city: 'Vellore',
    //     position: 'Vice President/National Extension Officer',
    //     image: 'https://assets.tangentindia.org/assets/team/nalini-prasad.jpeg'
    // },
    // {
    //     name: 'Jyoti Singh Deo',
    //     city: 'Bhubaneshwar',
    //     position: 'Imm Past President',
    //     image: 'https://assets.tangentindia.org/assets/team/jyoti-singh-deo.jpeg'
    // },
    // {
    //     name: 'Smita Chopra',
    //     city: 'Bangalore',
    //     position: 'Secretary',
    //     image: 'https://assets.tangentindia.org/assets/team/smita-chopra.jpeg'
    // },
    // {
    //     name: 'Lakshmi Siddharthan',
    //     city: 'Salem',
    //     position: 'Treasurer',
    //     image: 'https://assets.tangentindia.org/assets/team/lakshmi-siddharthan.jpeg'
    // },
    // {
    //     name: 'Sikata Das',
    //     city: '',
    //     position: 'International Relations Officer',
    //     image: 'https://assets.tangentindia.org/assets/team/sikata-das.jpeg'
    // },
    // {
    //     name: 'Meera Mohan',
    //     city: '',
    //     position: 'Editor',
    //     image: 'https://assets.tangentindia.org/assets/team/meera-mohan.jpg'
    // },
    // {
    //     name: 'Sonal Desai',
    //     city: '',
    //     position: 'Projects Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/sonal-desai.jpeg'
    // },
    // {
    //     name: 'Dr. Pawan Jain',
    //     city: '',
    //     position: 'Fellowship Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/pawan-jain.jpg'
    // },
    // {
    //     name: 'Ginni Singh',
    //     city: '',
    //     position: 'Fellowship Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/ginni-singh.jpeg'
    // },
    // {
    //     name: 'Archana Bhagat',
    //     city: '',
    //     position: 'Digital Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/archana-bhagat.jpg'
    // },
    // {
    //     name: 'Kavita Khara',
    //     city: '',
    //     position: 'Secretariat Coordinator',
    //     image: 'https://assets.tangentindia.org/assets/team/kavita-khara.jpeg'
    // },
    // {
    //     name: 'Sunayna Sugla',
    //     city: '',
    //     position: 'Extension Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/sunanya-sugla.jpeg'
    // },
    // {
    //     name: 'Himal Parikh',
    //     city: '',
    //     position: 'International Extension',
    //     image: 'https://assets.tangentindia.org/assets/team/himal-parikh.jpeg'
    // },
    // {
    //     name: 'Rashmi Kale',
    //     city: '',
    //     position: 'LC Contact Officer',
    //     image: 'https://assets.tangentindia.org/assets/team/rashmi-kale.jpeg'
    // },
    // {
    //     name: 'Pooja Galundia',
    //     city: '',
    //     position: 'MTM Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/pooja-galundia.jpg'
    // },
    // {
    //     name: 'Archana Barma',
    //     city: '',
    //     position: 'AGM Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/dummy.webp'
    // },
    // {
    //     name: 'Swati Saraf',
    //     city: '',
    //     position: 'AGM Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/swati-saraf.jpeg'
    // },
    // {
    //     name: 'Hema Yadavalli',
    //     city: '',
    //     position: 'HT and Training',
    //     image: 'https://assets.tangentindia.org/assets/team/hema-yadavalli.jpeg'
    // },
    // {
    //     name: 'Kala Ramki',
    //     city: '',
    //     position: 'Heels on Wheels',
    //     image: 'https://assets.tangentindia.org/assets/team/dummy.webp'
    // },
    // {
    //     name: 'PP Jogi Mittal',
    //     city: '',
    //     position: 'Rules Convenor',
    //     image: 'https://assets.tangentindia.org/assets/team/jogi-mittal.jpeg'
    // },
    // {
    //     name: 'PP Bijal Kalbag',
    //     city: 'Mumbai',
    //     position: 'National Advisor',
    //     image: 'https://assets.tangentindia.org/assets/team/bijal-kalbag.jpeg'
    // },
];

// const officeBearers = [
//     {
//         "city": "Mumbai",
//         "club": 1,
//         "chairperson": "Jogi Mittal",
//         "secretary": "Bijal Kalbag"
//     },
//     {
//         "city": "Delhi",
//         "club": 2,
//         "chairperson": "PAWAN JAIN",
//         "secretary": "Aruna Kohli"
//     },
//     {
//         "city": "Delhi",
//         "club": 3,
//         "chairperson": "Jayshree Thapar",
//         "secretary": "Bhupinder Kaur Chawla"
//     },
//     {
//         "city": "Hyderabad",
//         "club": 4,
//         "chairperson": "Rituu Singh",
//         "secretary": "Keerti Jaiswal"
//     },
//     {
//         "city": "Bangalore",
//         "club": 5,
//         "chairperson": "Nandita  Sanghani",
//         "secretary": "Vinder Bindra"
//     },
//     {
//         "city": "Mumbai",
//         "club": 6,
//         "chairperson": "Priya Srinivasu",
//         "secretary": "Alpa Karkhanis"
//     },
//     {
//         "city": "Kolkata",
//         "club": 7,
//         "chairperson": "Priti Agarwal",
//         "secretary": "Swati Saraf"
//     },
//     {
//         "city": "Bhubaneswar",
//         "club": 8,
//         "chairperson": "Gargi  Bhattacharya",
//         "secretary": "Kawaljit Kaur"
//     },
//     {
//         "city": "Kolkata",
//         "club": 9,
//         "chairperson": "Dimple Jain",
//         "secretary": "Archana Swaika"
//     },
//     {
//         "city": "Bangalore",
//         "club": 10,
//         "chairperson": "Divya A. Hiranandani",
//         "secretary": "Bhanu Ramaswami"
//     },
//     {
//         "city": "Nagpur",
//         "club": 11,
//         "chairperson": "Divya Sharma",
//         "secretary": "Meenakshi Sial"
//     },
//     {
//         "city": "Lucknow",
//         "club": 12,
//         "chairperson": "RITU SINGH",
//         "secretary": "MEETA RASTOGI"
//     },
//     {
//         "city": "Bangalore",
//         "club": 14,
//         "chairperson": "Anjali Poddar",
//         "secretary": "Deepa Mittal"
//     },
//     {
//         "city": "Mumbai",
//         "club": 15,
//         "chairperson": "Veena Vijaynagar",
//         "secretary": "Sangeeta Ajwani"
//     },
//     {
//         "city": "Bhilwara",
//         "club": 16,
//         "chairperson": "Vasudha Ladha",
//         "secretary": "Karishma Garg"
//     },
//     {
//         "city": "Raipur",
//         "club": 17,
//         "chairperson": "Neha Kasliwal",
//         "secretary": "Jyoti Bhojasiya"
//     },
//     {
//         "city": "Bangalore",
//         "club": 18,
//         "chairperson": "KAVITHA SRIDHAR",
//         "secretary": "SMITA CHOPRA"
//     },
//     {
//         "city": "Bangalore",
//         "club": 19,
//         "chairperson": "SANGEETA MATA",
//         "secretary": "SHAMIN FAZAL"
//     },
//     {
//         "city": "Chennai",
//         "club": 20,
//         "chairperson": "Yamini Deepak",
//         "secretary": "SANGEETHA GOEL"
//     },
//     {
//         "city": "Vadodra",
//         "club": 21,
//         "chairperson": "Kaushangi Dholikia",
//         "secretary": "Manasee Parikh"
//     },
//     {
//         "city": "Hubli",
//         "club": 22,
//         "chairperson": "Medha . Jagannath",
//         "secretary": "Meera . M . Tenginkai"
//     },
//     {
//         "city": "Salem",
//         "club": 23,
//         "chairperson": "Roopali Prabhu",
//         "secretary": "Dr. Manjoo Shree. N"
//     },
//     {
//         "city": "Vellore",
//         "club": 24,
//         "chairperson": "Surekha Ravindran",
//         "secretary": "Fahmeeda Mushther"
//     },
//     {
//         "city": "Cuttak",
//         "club": 25,
//         "chairperson": "Sreenita Goenka",
//         "secretary": "Neelam Kandoi"
//     },
//     {
//         "city": "Pondicherry",
//         "club": 26,
//         "chairperson": "Vidyalakshmi. C",
//         "secretary": "Manoja. S"
//     },
//     {
//         "city": "Coimbatore",
//         "club": 27,
//         "chairperson": "Vanmathi Balakrishnan",
//         "secretary": "Jayanthi Murthy"
//     },
//     {
//         "city": "Siligudi",
//         "club": 28,
//         "chairperson": "Rinky Agarwalla",
//         "secretary": "Rachna Golyan"
//     },
//     {
//         "city": "Hubli",
//         "club": 29,
//         "chairperson": "Pratibha S. Belgavi",
//         "secretary": "Dr Pama Adnur"
//     },
//     {
//         "city": "Hubli",
//         "club": 30,
//         "chairperson": "Sheela Ostwal",
//         "secretary": "Poorvi Rahul Vikamshi"
//     },
//     {
//         "city": "Bangalore",
//         "club": 31,
//         "chairperson": "Latha Bhaskar",
//         "secretary": "Lis  Wills"
//     },
//     {
//         "city": "Surat",
//         "club": 32,
//         "chairperson": "Pinakini Reshamwala",
//         "secretary": "Sangita Rathod"
//     },
//     {
//         "city": "Delhi",
//         "club": 33,
//         "chairperson": "Tina Mittal",
//         "secretary": "ARTI HANDA"
//     },
//     {
//         "city": "Vishakapatnam",
//         "club": 34,
//         "chairperson": "Rajini Chitra",
//         "secretary": "Sunita Agarwal"
//     },
//     {
//         "city": "Tirupur",
//         "club": 35,
//         "chairperson": "Raji Ashokan",
//         "secretary": "Sangeetha Sriram"
//     },
//     {
//         "city": "Lucknow",
//         "club": 36,
//         "chairperson": "Shilpi Bansal",
//         "secretary": "Ruchi Kapoor"
//     },
//     {
//         "city": "Belgaum",
//         "club": 38,
//         "chairperson": "Triveni A Bagi",
//         "secretary": "Sangeeta Chandak"
//     },
//     {
//         "city": "Tumkur",
//         "club": 39,
//         "chairperson": "Manasi prashanth",
//         "secretary": "Savitha Bharatheesh"
//     },
//     {
//         "city": "Bangalore",
//         "club": 40,
//         "chairperson": "Aarti Shroff",
//         "secretary": "Swati Agarwal"
//     },
//     {
//         "city": "Davangere",
//         "club": 41,
//         "chairperson": "Ashvini Thomas",
//         "secretary": "Vidya Niranjan"
//     },
//     {
//         "city": "Pondicherry",
//         "club": 42,
//         "chairperson": "Kala Ramakrishnan",
//         "secretary": "V Kalpana Jayaram"
//     },
//     {
//         "city": "Bangalore",
//         "club": 43,
//         "chairperson": "Amritha kinger",
//         "secretary": "Anju Madaan"
//     },
//     {
//         "city": "Kannur",
//         "club": 44,
//         "chairperson": "Shine Benaven",
//         "secretary": "Shalini George"
//     },
//     {
//         "city": "Ahmedabad",
//         "club": 45,
//         "chairperson": "Payal Kejriwal",
//         "secretary": "Krati Agarwal"
//     },
//     {
//         "city": "Pune",
//         "club": 46,
//         "chairperson": "Kinjal Gala",
//         "secretary": "Artavi Dedhia"
//     },
//     {
//         "city": "Conoor",
//         "club": 47,
//         "chairperson": "Anu.P.Mahbubani",
//         "secretary": "Malavika Jain"
//     },
//     {
//         "city": "Coimbatore",
//         "club": 48,
//         "chairperson": "Madhu Wadhwa",
//         "secretary": "Shalaka vora"
//     },
//     {
//         "city": "Bangalore",
//         "club": 49,
//         "chairperson": "JAGRUTI . Y . SHETH",
//         "secretary": "Bhavna Bajaj"
//     },
//     {
//         "city": "Vellore",
//         "club": 50,
//         "chairperson": "Andal Badrinath",
//         "secretary": "Gayathri Devi Kumaran"
//     },
//     {
//         "city": "Chennai",
//         "club": 51,
//         "chairperson": "MEEANSKSHI GUPTA",
//         "secretary": "Shanta Narayanan"
//     },
//     {
//         "city": "Pilibhit",
//         "club": 53,
//         "chairperson": "Rashmi Goel",
//         "secretary": "Asha Singh"
//     },
//     {
//         "city": "Nagpur",
//         "club": 54,
//         "chairperson": "Manisha Verma",
//         "secretary": "Neha Thapar"
//     },
//     {
//         city: "Hubli",
//         club: 55,
//         chairperson: "Lopa Saigal",
//         secretary: "Darshana Mane"
//
//     }
// ]

const officeBearers = [
    {
        "city": "Mumbai",
        "club": 1,
        "chairperson": "Paramjeet Kaur",
        "secretary": "Minal Sandu"
    },
    {
        "city": "Delhi",
        "club": 2,
        "chairperson": "Pawan Jain",
        "secretary": "Archana Dixit"
    },
    {
        "city": "Delhi",
        "club": 3,
        "chairperson": "Mamta Bhargava",
        "secretary": "Reena Malhotra"
    },
    {
        "city": "Hyderabad",
        "club": 4,
        "chairperson": "Savita Alla",
        "secretary": "Archana Govindrajan "
    },
    {
        "city": "Bangalore",
        "club": 5,
        "chairperson": "Nandita  Sanghani",
        "secretary": "Era Goel"
    },
    {
        "city": "Mumbai",
        "club": 6,
        "chairperson": "Priya Srinivasu",
        "secretary": "Alpa Karkhanis"
    },
    {
        "city": "Kolkata",
        "club": 7,
        "chairperson": "Priti Agarwal",
        "secretary": "Swati Saraf"
    },
    {
        "city": "Bhubaneswar",
        "club": 8,
        "chairperson": "Renu Gupta",
        "secretary": "Jyoti Singh Deo"
    },
    {
        "city": "Kolkata",
        "club": 9,
        "chairperson": "Archana Barma",
        "secretary": "Archana Swaika"
    },
    {
        "city": "Bangalore",
        "club": 10,
        "chairperson": "Sukanya Lakshmi Narayan",
        "secretary": "Divya A. Hiranandani"
    },
    {
        "city": "Nagpur",
        "club": 11,
        "chairperson": "Meenakshi Sial",
        "secretary": "Divya Ladha"
    },
    {
        "city": "Lucknow",
        "club": 12,
        "chairperson": "Meeta Rastogi",
        "secretary": "Jyoti Dewan "
    },
    {
        "city": "Bangalore",
        "club": 14,
        "chairperson": "Anuradha Vijayanagar",
        "secretary": "Varkha Jeetendra Valecha"
    },
    {
        "city": "Mumbai",
        "club": 15,
        "chairperson": "Veena Vijaynagar",
        "secretary": "Sangeeta Ajwani"
    },
    {
        "city": "Bhilwara",
        "club": 16,
        "chairperson": "Mridula Mansinghka",
        "secretary": "Rashmi Garg"
    },
    {
        "city": "Raipur",
        "club": 17,
        "chairperson": "Prena Gautam",
        "secretary": "Shilpa sarda"
    },
    {
        "city": "Bangalore",
        "club": 18,
        "chairperson": "Kajol Bhatia",
        "secretary": "Hari Priya"
    },
    {
        "city": "Bangalore",
        "club": 19,
        "chairperson": "Shamim Fazal",
        "secretary": "Rachna Gupta"
    },
    {
        "city": "Chennai",
        "club": 20,
        "chairperson": "Namita Maggon",
        "secretary": "Jayanthi Vummidi"
    },
    {
        "city": "Vadodra",
        "club": 21,
        "chairperson": "Kaushangi Dholikia",
        "secretary": "Meena Kemkar"
    },
    {
        "city": "Hubli",
        "club": 22,
        "chairperson": "Shantala S Shetty",
        "secretary": "Gayathri Dhruva Desai"
    },
    {
        "city": "Salem",
        "club": 23,
        "chairperson": "Dr. Manjoo Shree N",
        "secretary": "Roopali Prabhu"
    },
    {
        "city": "Vellore",
        "club": 24,
        "chairperson": "Shyamala Ravikumar",
        "secretary": "Sreevalli Shanmugam"
    },
    {
        "city": "Cuttak",
        "club": 25,
        "chairperson": "Payal Rathor",
        "secretary": "Neelam Kandoi"
    },
    {
        "city": "Pondicherry",
        "club": 26,
        "chairperson": "Vidyalakshmi C",
        "secretary": "Manoja S"
    },
    {
        "city": "Coimbatore",
        "club": 27,
        "chairperson": "Kalpana Ramesh",
        "secretary": "Jayanthi Murthy"
    },
    {
        "city": "Siligudi",
        "club": 28,
        "chairperson": "Samta Bhansali",
        "secretary": "Ritu Poddar"
    },
    {
        "city": "Hubli",
        "club": 29,
        "chairperson": "Noor Aisha Firdous Sadiq",
        "secretary": "Neha M Pawar"
    },
    {
        "city": "Hubli",
        "club": 30,
        "chairperson": "Neepa Mehta",
        "secretary": "Divya Shetty"
    },
    {
        "city": "Bangalore",
        "club": 31,
        "chairperson": "Latha Bhaskar",
        "secretary": "Lis  Wills"
    },
    {
        "city": "Surat",
        "club": 32,
        "chairperson": "Sangita Rathod",
        "secretary": "Priti Jariwala"
    },
    {
        "city": "Delhi",
        "club": 33,
        "chairperson": "Tina Mittal",
        "secretary": "Renu Lal"
    },
    {
        "city": "Vishakapatnam",
        "club": 34,
        "chairperson": "Reena Somani",
        "secretary": "Anita Nuthakki"
    },
    {
        "city": "Tirupur",
        "club": 35,
        "chairperson": "Pooja Alexander",
        "secretary": "Kripaa Shetty"
    },
    {
        "city": "Lucknow",
        "club": 36,
        "chairperson": "Aparna Narain",
        "secretary": "Sakshi Jain"
    },
    {
        "city": "Mumbai",
        "club": 37,
        "chairperson": "Meher Mirpuri",
        "secretary": "Anisha Agarwal"
    },
    {
        "city": "Belgaum",
        "club": 38,
        "chairperson": "Sangeeta Chandak",
        "secretary": "Soniya Harpreet  Khurana"
    },
    {
        "city": "Tumkur",
        "club": 39,
        "chairperson": "Manasi Prashanth",
        "secretary": "Savitha Bharatheesh"
    },
    {
        "city": "Bangalore",
        "club": 40,
        "chairperson": "Aarti Gupta",
        "secretary": "Shilpi Shah"
    },
    {
        "city": "Davangere",
        "club": 41,
        "chairperson": "Ashvini Thomas",
        "secretary": "Vidya Niranjan"
    },
    {
        "city": "Pondicherry",
        "club": 42,
        "chairperson": "Kala Ramakrishnan",
        "secretary": "V Kalpana Jayaram"
    },
    {
        "city": "Bangalore",
        "club": 43,
        "chairperson": "Rachna Malhotra",
        "secretary": "Sonali Soni"
    },
    {
        "city": "Kannur",
        "club": 44,
        "chairperson": "Shine Benaven",
        "secretary": "Shalini George"
    },
    {
        "city": "Ahmedabad",
        "club": 45,
        "chairperson": "Medha Mehrishi",
        "secretary": "Shikha Bansal"
    },
    {
        "city": "Pune",
        "club": 46,
        "chairperson": "Artavi Dedhia",
        "secretary": "Sheetal Vijay Agarwal"
    },
    {
        "city": "Coonoor",
        "club": 47,
        "chairperson": "Nayana Arjun",
        "secretary": "Punita Dani"
    },
    {
        "city": "Coimbatore",
        "club": 48,
        "chairperson": "Rachana Jaiin",
        "secretary": "Nandini Premkumar"
    },
    {
        "city": "Bangalore",
        "club": 49,
        "chairperson": "Bhavna Bajaj",
        "secretary": "Ishrat Hussain"
    },
    {
        "city": "Vellore",
        "club": 50,
        "chairperson": "Nanthini M",
        "secretary": "Saritha Sathish"
    },
    {
        "city": "Chennai",
        "club": 51,
        "chairperson": "Meenakshi Gupta",
        "secretary": "Shanta Narayanan"
    },
    {
        "city": "Bareilly",
        "club": 52,
        "chairperson": "Shradha Khandelwal",
        "secretary": "Ruchi Jasoria"
    },
    {
        "city": "Pilibhit",
        "club": 53,
        "chairperson": "Rashmi Goel",
        "secretary": "Asha Singh"
    },
    {
        "city": "Nagpur",
        "club": 54,
        "chairperson": "Manisha Verma",
        "secretary": "Shweta Tapadiya"
    },
    {
        "city": "Hubli",
        "club": 55,
        "chairperson": "Nandini Agadi",
        "secretary": "Ranjita"
    },
    {
        "city": "Vijayawada",
        "club": 56,
        "chairperson": "Sridevi Prasad",
        "secretary": "Desu Lakshmi Suchitra Yogesh"
    },
    {
        "city": "Hyderabad",
        "club": 57,
        "chairperson": "Sudha Sinha",
        "secretary": "Rachna Agarwal"
    }
]

export default class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({
            ...this.state,
            showModal: true
        })
    }

    hideModal() {
        this.setState({
            ...this.state,
            showModal: false
        })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/team/president-message.png')",
            backgroundSize: 'contain'
        };
        return (
            <div id="team">
                <Seo title='Meet the Tangent India Team | Tangent India'
                     description="Tangent India's Team comprises of a National Board and the Office Bearers of each Tangent India Club across the country."
                />
                {/*<div id="team-header-background" style={sectionStyle}  onClick={() => console.log('hi')}>*/}
                {/*    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'*/}
                {/*         className="hidden header-foreground" alt=""/>*/}
                {/*</div>*/}
                <div className={'text-center'} onClick={() => console.log('hah')}>
                    <img src='https://assets.tangentindia.org/assets/team/president-message.png'
                         className="text-center" width={'80%'} alt="" onClick={this.showModal}/>
                </div>
                <Modal show={this.state.showModal} onHide={this.hideModal} lg centered fullscreen>
                    <Modal.Header closeButton>
                        {/*<Modal.Title>Modal heading</Modal.Title>*/}
                    </Modal.Header>
                    <Modal.Body>
                        <img src='https://assets.tangentindia.org/assets/team/president-message.png' width={'100%'}/>
                    </Modal.Body>
                    <Modal.Footer>
                        {/*<Button variant="secondary" onClick={() => this.setState({...this.state, modal: false})}>*/}
                        {/*    Close*/}
                        {/*</Button>*/}
                        {/*<Button variant="primary" onClick={() => this.setState({...this.state, modal: false})}}>*/}
                        {/*    Save Changes*/}
                        {/*</Button>*/}
                    </Modal.Footer>
                </Modal>

                <div className="title center">
                    <h1>
                        Meet the Tangent India Team
                    </h1>
                </div>
                <div className="section">
                    <Container>
                        <Row>
                            <h2>
                                Board Members 2023-24
                            </h2>
                            {board.length === 0 && <Row><h3 className={'text-center'}>Coming Soon!</h3></Row>}
                            <Row className="board-members">
                                {board.map((member, idx) => {
                                    return (
                                        <BoardMember
                                            key={idx}
                                            name={member.name}
                                            city={member.city}
                                            position={member.position}
                                            image={member.image}
                                        />
                                    );
                                })}
                            </Row>
                        </Row>
                    </Container>
                </div>
                <div className="section grey-bg">
                    <Container>
                        <Row>
                            <h2>
                                List of Office Bearers
                            </h2>
                            <Row className="board-members">
                                {officeBearers.map((club, idx) => {
                                    return (
                                        <OfficeBearerInfo
                                            key={idx}
                                            chairperson={club.chairperson}
                                            city={club.city}
                                            secretary={club.secretary}
                                            number={club.club}
                                        />
                                    );
                                })}
                            </Row>
                        </Row>
                    </Container>
                </div>
                <div className="section">
                    <Container>
                        <Row>
                            <h2>
                                Roll of Honor
                            </h2>
                            <RollOfHonor/>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}


