import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Seo from "../../Seo";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ClubScreen from "./ClubScreen";
import {getUser} from "./utils";

const permissions = [
    {
        label: 'View Member Directory',
        name: 'directory_permission'
    },
    {
        label: 'Submit AAF Form',
        name: 'form_permission'
    },
    {
        label: 'Create Event',
        name: 'event_permission'
    },
    {
        label: 'View Internal Files',
        name: 'internalfiles_permission'
    }
];

export default class GrantPermissions extends React.Component {
    constructor(props) {
        super(props);
        let stateVal = {
            validated: false
        }
        permissions.forEach(permission => {
            stateVal[permission['name']] = {
                resp: false
            }
        })
        this.state = stateVal;
        this.changeState = this.changeState.bind(this);
        this.changePermissionState = this.changePermissionState.bind(this);
        this.changeExpiryDate = this.changeExpiryDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    changePermissionState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                resp: !this.state[e.target.name]['resp']
            }
        })
    }

    changeExpiryDate(e) {
        this.setState({
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                date: e.target.value
            }
        })
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();

        this.setState({
            validated: true
        })

        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        this.setState({
            loading: true
        })

        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/permissions?userId=' + getUser()['sub'], {
            method: 'POST',
            body: JSON.stringify({
                email: this.state.email,
                directory_permission: this.state.directory_permission,
                form_permission: this.state.form_permission,
                event_permission: this.state.event_permission,
                internalfiles_permission: this.state.internalfiles_permission
            })
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    ...this.state,
                    alert: true,
                    alertMessage: response['message'],
                    alertType: response['type'],
                    loading: false,
                    // name: '',
                    // email: '',
                    // message: ''
                });
            })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
        };
        return (
            <div id="home">
                <Seo title='Tangent India - Grant Permissions' noindex={true}/>
                <div id="home-header-background" style={sectionStyle}>
                    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'
                         className="hidden header-foreground" alt=""/>
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href='/admin'>Admin</Breadcrumb.Item>
                    <Breadcrumb.Item active>Grant Permissions</Breadcrumb.Item>
                </Breadcrumb>
                <ClubScreen/>
                <div className='title center'>
                    <h1>Grant Permissions</h1>
                </div>
                <Container>
                    <Container className='section'>
                        {this.state.alertType ?
                            <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Row>
                                <Col sm>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email of Member</Form.Label>
                                        <Form.Control required type="email" placeholder="Enter email"
                                                      value={this.state.email}
                                                      name='email'
                                                      onChange={this.changeState}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="permissions">
                                    <Form.Label>Permissions to Grant</Form.Label>
                                    {permissions.map(permission => {
                                        return (
                                            <Row key={permission['name']}>
                                                <Col>
                                                    <Form.Check type="checkbox"
                                                                label={permission['label']}
                                                                checked={this.state[permission['name']]['resp']}
                                                                name={permission['name']}
                                                                onChange={this.changePermissionState}/>
                                                </Col>
                                                <Col>
                                                    {this.state[permission['name']]['resp'] ?
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Permission End Date</Form.Label>
                                                            <Form.Control type="date"
                                                                          required
                                                                          value={this.state[permission['name']]['date']}
                                                                          name={permission['name']}
                                                                          onChange={this.changeExpiryDate}
                                                            />
                                                        </Form.Group> :
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Permission End Date</Form.Label>
                                                            <Form.Control type="date"
                                                                          disabled
                                                                          value={this.state[permission['name']]['date']}
                                                            />
                                                        </Form.Group>
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Form.Group>
                            </Row>

                            <Row>
                                {this.state.alertType ?
                                    <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                                {this.state.loading && <Spinner animation="border"/>}
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Container>
            </div>
        );
    }
}