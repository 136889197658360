import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Seo from "../../Seo";
import ReCAPTCHA from 'react-google-recaptcha';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeState = this.changeState.bind(this);
        this.changeRecaptcha = this.changeRecaptcha.bind(this);
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false || !this.state.recaptcha) {
            event.stopPropagation();
            return;
        }

        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/contactUsForm', {
            method: 'POST',
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    ...this.state,
                    validated: true,
                    alert: true,
                    alertMessage: response['message'],
                    alertType: response['type'],
                    // name: '',
                    // email: '',
                    // message: ''
                });
            })
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    changeRecaptcha(e) {
        this.setState({
            ...this.state,
            recaptcha: e
        })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/contact-banner.jpeg')"
        };
        return (
            <div id="contact">
                <Seo title='Contact Tangent India | Tangent India'
                     description='Contact Tangent India, a Registered Society under the Tamil Nadu Societies Registration Act, 1975.'
                />
                <div id="contact-header-background" style={sectionStyle}>
                    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'
                         className="hidden header-foreground" alt=""/>
                </div>
                <div className="center title">
                    <h1>Contact Tangent India</h1>
                </div>
                <div>
                    <Container className="section">
                        <Row>
                            <Col>
                                <Row>
                                <iframe
                                    title="Location of Tangent India on Google Maps"
                                    className="iframe"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.558350697474!2d80.24163391482301!3d13.063760290795484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526668ae9e5851%3A0xdec25bea4cce7b64!2sRound+Table+House%2C+69%2C+Nungambakkam+High+Rd%2C+Subba+Road+Avenue%2C+Nungambakkam%2C+Chennai%2C+Tamil+Nadu+600034!5e0!3m2!1sen!2sin!4v1549968770372"
                                    width="600"
                                    height="450"
                                    frameBorder="0"
                                    allowFullScreen="allowfullscreen"/>
                                </Row>
                                <Row>
                                    Round Table House<br/>
                                    80, Nungambakkam High Rd,<br/>
                                    Nungambakkam,<br/>
                                    Chennai, Tamil Nadu 600034
                                </Row>
                                <Row>
                                    +91 9797173473
                                </Row>
                            </Col>
                            <Col>
                                {this.state.alertType ?
                                    <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control required type="input" placeholder="Enter name"
                                                      value={this.state.name}
                                                      name='name'
                                                      onChange={this.changeState}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control required type="email" placeholder="Enter email"
                                                      value={this.state.email}
                                                      name='email'
                                                      onChange={this.changeState}
                                        />
                                        <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formMessage">
                                        <Form.Label>Enter your message</Form.Label>
                                        <Form.Control required as="textarea" rows={3}
                                                      value={this.state.message}
                                                      name='message'
                                                      onChange={this.changeState}
                                        />
                                    </Form.Group>

                                    <ReCAPTCHA
                                        sitekey="6LfibwIeAAAAALATkXIkl7-3mX7iOMExJW5sXHgd"
                                        onChange={this.changeRecaptcha}
                                    />

                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}