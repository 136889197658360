import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";
import {getUser} from "./utils";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import halfYearlyPdf from './assets/half-yearly.pdf';
import LogoutButton from "./LogoutButton";
import Alert from "react-bootstrap/Alert";

export default class ClubScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
        this.viewInternalFiles = this.viewInternalFiles.bind(this);
    }

    componentDidMount() {
        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/admin?userId=' + getUser()['sub'])
            .then(response => response.json())
            .then(response => {
                this.setState({
                    ...this.state,
                    directory: response['directory_permission'],
                    aaf: response['form_permission'],
                    event: response['event_permission'],
                    internalfiles: response['internalfiles_permission'],
                    admin: response['admin_permission'],
                    loading: false
                })
            })
    }

    viewInternalFiles() {
        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/internal-files?userId=' + getUser()['sub'])
            .then(response => response.json())
            .then(response => {
                alert('The internal file system is opening. Please use the following credentials to login:\nUsername: ' + response['username'] + '\nPassword: ' + response['username'])
                let newWindow = window.open("https://d2oeo0f6as6be1.cloudfront.net/", "_blank");
                this.setState({
                    ...this.state,
                    popupBlocked: !newWindow || newWindow.closed || typeof newWindow.closed == 'undefined',
                    popupEmail: response['username']
                })
            })
    }

    render() {
        return (
            <div>
                <Container className='section center'>
                    {this.state.loading && <Spinner animation="border"/>}
                    {this.state.loading ||
                        <div>
                            {this.state.popupBlocked &&
                                <a href='https://d2oeo0f6as6be1.cloudfront.net' target='_blank' rel='noreferrer'>
                                    <Alert variant='success'>
                                        {'Your popup-blocker is preventing us from opening the Internal Files. Please use the following credentials to login:\nUsername: ' + this.state.popupEmail + '\nPassword: ' + this.state.popupEmail}
                                    </Alert>
                                </a>
                            }
                            <Row>
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" as={Link}
                                            to="/new-membership">
                                        New Member Form
                                    </Button>
                                </Col>
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" as={Link} to={halfYearlyPdf}>
                                        Half Yearly Report Format
                                    </Button>
                                </Col>
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" as={Link}
                                            to="/annual-form.pdf">
                                        Annual Report Format
                                    </Button>
                                </Col>
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" as={Link}
                                            to="/admin/request-permissions">
                                        Request Permissions
                                    </Button>
                                </Col>
                                <Col>
                                    <LogoutButton/>
                                </Col>
                            </Row>
                            <Row>
                                {this.state.aaf &&
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" as={Link}
                                            to="/admin/annual-administrative-fee-form">
                                        Annual Administrative Fee Form
                                    </Button>
                                </Col>}
                                {this.state.directory &&
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" as={Link}
                                            to="/admin/member-directory">
                                        Tangent Directory
                                    </Button>
                                </Col>}
                                {this.state.event &&
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" as={Link}
                                            to="/admin/create-event">
                                        Create Event
                                    </Button>
                                </Col>}
                                {this.state.internalfiles &&
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" onClick={this.viewInternalFiles}>
                                        Internal Files
                                    </Button>
                                </Col>}
                                {this.state.admin &&
                                <Col>
                                    <Button className='small-padding' variant="outline-secondary" as={Link}
                                            to="/admin/grant-permissions">
                                        Grant Permissions
                                    </Button>
                                </Col>}
                            </Row>
                        </div>
                    }
                </Container>
            </div>
        );
    }
}