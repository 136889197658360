import React from "react";
import {MetaTags} from "react-meta-tags";

export default class Seo extends React.Component {
    // props = {
    //      title
    //      description
    //      image
    // }
    render() {
        return (
            <MetaTags>
                <title>{this.props.title ? this.props.title : 'Tangent India'}</title>
                <meta
                    name="description"
                    content={this.props.description ? this.props.description : "For all thy Gifts we thank thee Lord Especially for the Gift of Continued Friendship, To continue the friendships formed in Ladies Circle and Round Table."}
                />
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="website"/>
                <meta property="og:description"
                      content={this.props.description ? this.props.description : "For all thy Gifts we thank thee Lord Especially for the Gift of Continued Friendship, To continue the friendships formed in Ladies Circle and Round Table"}
                />
                <meta property="og:title" content={this.props.title ? this.props.title : 'Tangent India'}/>
                <meta property="og:site_name" content="Tangent India"/>
                <meta property="og:image" content={this.props.image}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:label1" content="Est. reading time"/>
                <meta name="twitter:data1" content="14 minutes"/>
                <meta name="twitter:title" content={this.props.title ? this.props.title : 'Tangent India'}/>
                <meta name="twitter:description"
                      content={this.props.description ? this.props.description : "For all thy Gifts we thank thee Lord Especially for the Gift of Continued Friendship, To continue the friendships formed in Ladies Circle and Round Table"}
                />
                <meta name="twitter:image" content={this.props.image}/>
                {this.props.noindex ? <meta name="robots" content="noindex"/> : ''}

                <meta name="facebook-domain-verification" content="300p6aeqts3tjy1fucm7np2gkgfw5n" />
            </MetaTags>
        );
    }
}