import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from "react-router-dom";
import {
    AAF,
    About,
    AdminHome,
    AutoUpdatedDirectory,
    Contact,
    Directory,
    Events,
    Footer,
    GrantPermissions,
    Home,
    Membership,
    Navigation,
    NewMembership,
    PageNotFound,
    TangentTimes,
    Team
} from "./pages";
import {Auth0Provider} from "@auth0/auth0-react";
import {isLoggedIn} from "./pages/Admin/utils";
import EventCreator from "./pages/Admin/EventCreator";
import Extension from "./pages/Extension/Extension";
import RequestPermissions from "./pages/Admin/RequestPermissions";
import Downloads from "./pages/Downloads/Downloads";
import Privacy from "./pages/Legalese/Privacy";
import TermsConditions from "./pages/Legalese/TermsConditions";
import PricingRefundsCancellations from "./pages/Legalese/PricingRefundsCancellations";
import AafConfirmations from "./pages/AafConfirmations";

export default function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

ReactDOM.render(
    <Auth0Provider
        domain="tangentindia.eu.auth0.com"
        clientId="NdnPkyROviMHmYHaAfF5nUWwnELJP0SU"
        redirectUri={window.location.origin + '/admin'}
    >
        <Router>
            <ScrollToTop/>
            <Navigation/>
            <Switch>
                <Route path="/" exact>
                    <Home/>
                </Route>
                <Route path="/aaf-confirmations" exact>
                    <AafConfirmations/>
                </Route>
                <Route path='/pricing-refunds-cancellations'>
                    <PricingRefundsCancellations />
                </Route>
                <Route path="/administrivia">
                    <Downloads />
                </Route>
                <Route path="/about-us">
                    <About/>
                </Route>
                <Route path="/privacy">
                    <Privacy/>
                </Route>
                <Route path="/terms-conditions">
                    <TermsConditions/>
                </Route>
                <Route path="/meet-the-team">
                    <Team/>
                </Route>
                <Route path="/membership">
                    <Membership/>
                </Route>
                <Route path="/new-membership">
                    <NewMembership/>
                </Route>
                <Route path="/extensions">
                    <Extension/>
                </Route>
                <Route path="/events">
                    <Events/>
                </Route>
                <Route path="/publications">
                    <TangentTimes/>
                </Route>
                <Route path="/contact-us">
                    <Contact/>
                </Route>
                {/*<Route path="/admin" exact>*/}
                {/*    <AdminHome/>*/}
                {/*</Route>*/}
                {/*<Route path="/admin/annual-administrative-fee-form">*/}
                {/*    {!isLoggedIn() ? <Redirect to="/admin"/> : <AAF/>}*/}
                {/*</Route>*/}
                {/*<Route path="/admin/request-permissions">*/}
                {/*    {!isLoggedIn() ? <Redirect to="/admin"/> : <RequestPermissions/>}*/}
                {/*</Route>*/}
                {/*<Route path="/admin/directory">*/}
                {/*    {!isLoggedIn() ? <Redirect to="/admin"/> : <Directory/>}*/}
                {/*</Route>*/}
                {/*<Route path="/admin/member-directory">*/}
                {/*    {!isLoggedIn() ? <Redirect to="/admin"/> : <AutoUpdatedDirectory/>}*/}
                {/*</Route>*/}
                {/*<Route path="/admin/create-event">*/}
                {/*    {!isLoggedIn() ? <Redirect to="/admin"/> : <EventCreator/>}*/}
                {/*</Route>*/}
                {/*<Route path="/admin/grant-permissions">*/}
                {/*    {!isLoggedIn() ? <Redirect to="/admin"/> : <GrantPermissions/>}*/}
                {/*</Route>*/}
                <Route path="/">
                    <PageNotFound/>
                </Route>
            </Switch>
            <Footer/>
        </Router>
    </Auth0Provider>,
    document.getElementById("root")
);