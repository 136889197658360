import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Seo from "../../Seo";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ClubScreen from "./ClubScreen";
import {getUser} from "./utils";
import Table from "react-bootstrap/Table";

// const permissions = [
//     {
//         slug: 'member',
//         label: 'Club Member',
//         permissions: ['View Member Directory']
//     },
// ];

export default class RequestPermissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            user: getUser()
        }
        // permissions.forEach(permission => {
        //     stateVal[permission['slug']] = {
        //         resp: false
        //     }
        // })
        // stateVal['user'] = getUser();
        // this.state = stateVal;
        this.changeState = this.changeState.bind(this);
        this.changePermissionState = this.changePermissionState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    changePermissionState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: {
                ...this.state[e.target.name],
                resp: !this.state[e.target.name]['resp']
            }
        })
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();

        this.setState({
            validated: true
        })

        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        this.setState({
            loading: true
        })

        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/requestPermission', {
            method: 'POST',
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    ...this.state,
                    alert: true,
                    alertMessage: response['message'],
                    alertType: response['type'],
                    loading: false,
                    // name: '',
                    // email: '',
                    // message: ''
                });
            })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
        };
        return (
            <div id="home">
                <Seo title='Tangent India - Request Permissions' noindex={true}/>
                <div id="home-header-background" style={sectionStyle}>
                    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'
                         className="hidden header-foreground" alt=""/>
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href='/admin'>Admin</Breadcrumb.Item>
                    <Breadcrumb.Item active>Request Permissions</Breadcrumb.Item>
                </Breadcrumb>
                <ClubScreen/>
                <div className='title center'>
                    <h1>Request Permissions</h1>
                </div>
                <Container>
                    <Container className='section'>
                        {this.state.alertType ?
                            <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <Row>
                                <Col sm>
                                    <p><strong>User Information</strong></p>
                                    <Table>
                                        <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>
                                                <Form.Control required type="input" placeholder="Enter name"
                                                              value={this.state.name}
                                                              name='name'
                                                              onChange={this.changeState}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{this.state.user.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Club Num</td>
                                            <td>
                                                <Form.Control required type="number" placeholder="Enter club"
                                                              value={this.state.club}
                                                              name='club'
                                                              onChange={this.changeState}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            {/*<Row>*/}
                            {/*    <Form.Group className="mb-3" controlId="permissions">*/}
                            {/*        <Form.Label>Requested Permissions</Form.Label>*/}
                            {/*        {permissions.map(permission => {*/}
                            {/*            return (*/}
                            {/*                <Row key={permission['name']}>*/}
                            {/*                    <Col>*/}
                            {/*                        <Form.Check type="checkbox"*/}
                            {/*                                    label={permission['label']}*/}
                            {/*                                    checked={this.state[permission['slug']]['resp']}*/}
                            {/*                                    name={permission['slug']}*/}
                            {/*                                    onChange={this.changePermissionState} />*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*            )*/}
                            {/*        })}*/}
                            {/*    </Form.Group>*/}
                            {/*</Row>*/}

                            <Row>
                                {this.state.alertType ?
                                    <Alert variant={this.state.alertType}>{this.state.alertMessage}</Alert> : ''}
                                {this.state.loading && <Spinner animation="border"/>}
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Container>
            </div>
        );
    }
}