import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import Seo from "../../Seo";

function Home() {
    const sectionStyle = {
        backgroundImage: "url('https://assets.tangentindia.org/assets/home_banner_back.webp')"
    };
    return (
        <div id="home">
            <Seo title='Tangent India - Home'/>
            <div id="home-header-background" style={sectionStyle}>
                <img src='https://assets.tangentindia.org/assets/2023-logo.png' className="header-foreground"
                     alt=""/>
            </div>
            <div>
                <Container>
                    <Row className="section">
                        <Col sm className="center">
                            <img src='https://assets.tangentindia.org/assets/prayer.webp' className="small-image center" alt="Lady Praying"/>
                        </Col>
                        <Col sm className="center">
                            <p className="section-subheader center">For all thy Gifts we thank thee Lord Especially for
                                the Gift of Continued Friendship</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="grey-bg">
                <Container>
                    <Row className="section">
                        <Col sm className="center">
                            <p className="section-header center">Motto</p>
                            <p className="section-subheader center">Friendship Continued Forever</p>
                        </Col>
                        <Col sm className="center">
                            <img src='https://assets.tangentindia.org/assets/motto.webp' className="small-image center" alt="People holding hands"/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container className="section">
                    <p className="section-header">Objectives</p>

                    <ul className="fa-ul">
                        {
                            [
                                "To continue the friendships formed in Ladies Circle and Round Table",
                                "To work side by side with Round Table India, Ladies Circle India & 41 Clubs of India",
                                "To share and enjoy activities based on fun and fellowship locally and globally",
                                "To reach out to the needy and the deserving wherever possible",
                                "To be non sectarian and non-political"
                            ].map((item, idx) => {
                                return (
                                    <li key={idx} className="objective">
                                        <FontAwesomeIcon icon={faArrowCircleRight} listItem/>
                                        {item}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </Container>
            </div>

        </div>
    );
}

export default Home;