import Cookies from 'universal-cookie';

const cookies = new Cookies();
const cookieName = 'tangent-user';

export function setUser(user) {
    let newUser = {...user, setTime: getCurrentTimestamp(), expireTime: getExpireTime()}
    cookies.set(cookieName, JSON.stringify(newUser), {path: '/', maxAge: 21600});
}

export function getUser() {
    return cookies.get(cookieName);
}

export function isLoggedIn() {
    return cookies.get(cookieName) && cookies.get(cookieName)['expireTime'] && cookies.get(cookieName)['expireTime'] > getCurrentTimestamp();
}

export function logoutCookie() {
    cookies.remove(cookieName);
}

function getCurrentTimestamp() {
    return (+new Date());
}

function getExpireTime() {
    return getCurrentTimestamp() + 21600000;
}