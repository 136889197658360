import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import {logoutCookie} from './utils';

const LogoutButton = () => {
    const {logout} = useAuth0();

    const logoutFunc = () => {
        logout({returnTo: window.location.origin});
        logoutCookie();
    }

    return (<Button variant="outline-secondary" onClick={() => logoutFunc()}>Logout</Button>);
};

export default LogoutButton;
