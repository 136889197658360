import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Seo from "../../Seo";
import Spinner from "react-bootstrap/Spinner";

export default class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            loading: true
        }
    }

    componentDidMount() {
        fetch('https://bz9obpme63.execute-api.ap-south-1.amazonaws.com/events')
            .then(response => response.json())
            .then(response => response['Items'])
            .then(response => response.filter(event => new Date(event['end_date']['S']) > new Date()))
            .then(response => {
                response.sort(function (x, y) {
                    const firstStartDate = new Date(x['start_date']['S']);
                    const firstEndDate = new Date(x['end_date']['S']);
                    const secondStartDate = new Date(y['start_date']['S']);
                    const secondEndDate = new Date(y['end_date']['S']);
                    if (firstStartDate < secondStartDate) {
                        return -1;
                    }
                    if (firstStartDate > secondStartDate) {
                        return 1;
                    }
                    if (firstEndDate < secondEndDate) {
                        return -1;
                    }
                    if (firstEndDate > secondEndDate) {
                        return 1;
                    }
                    return 0;
                });
                return response;
            })
            .then(response => {
                this.setState({
                    ...this.state,
                    events: response,
                    loading: false
                })
            })
    }

    render() {
        const sectionStyle = {
            backgroundImage: "url('https://assets.tangentindia.org/assets/newsletter-banner.jpeg')"
        };
        return (
            <div id="events">
                <Seo title='Events | Tangent India'
                     description="Details of the upcoming Tangent India Events. Learn about upcoming events with Tangent India and Tangent Club International."
                />
                <div id="newsletter-header-background" style={sectionStyle}>
                    <img src='https://assets.tangentindia.org/assets/home_banner_front.webp'
                         className="hidden header-foreground" alt=""/>
                </div>
                <div className="center title">
                    <h1>Events</h1>
                </div>
                <div className="section center">
                    <Container>
                        <Row>
                            {this.state.events.length === 0 && this.state.loading && <Spinner animation="border"/>}
                            {this.state.events.length === 0 && !this.state.loading && <h4>No Events Found</h4>}
                            {this.state.events.map((event, idx) => {
                                return (
                                    <Col key={idx} sm>
                                        <strong>{event['title']['S']}</strong><br/>
                                        Start Time: {event['start_date']['S']} {event['start_time']['S']} IST<br/>
                                        End Time: {event['end_date']['S']} {event['end_time']['S']} IST<br/>
                                        {event['description']['S']}<br/>
                                        <a href={event['online_location']['S']} target='_blank' rel='noreferrer'>Click
                                            here to join</a><br/>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}